import Draw from '@mui/icons-material/DrawOutlined';
import VideoCameraBack from '@mui/icons-material/VideoCameraBackOutlined';
import RocketLaunch from '@mui/icons-material/RocketLaunchOutlined';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import ResponsiveIcon from "../ui-components/ResponsiveIcon";

export const skills = {
  uiDesign: {
    title: "UI | UX Design",
    icon: <ResponsiveIcon IconComponent={DeveloperBoardIcon}/>,
    tools: {
      1: {
        title: "Figma",
        level: 4,
      },
      2: {
        title: "Adobe XD",
        level: 3,
      },
    }
  },
  webDev: {
    title: "Webentwicklung und CMS",
    icon: <ResponsiveIcon IconComponent={DeveloperModeIcon}/>,
    tools: {
      1: {
        title: "HTML | CSS | JavaScript",
        level: 4,
      },
      2: {
        title: "React",
        level: 3,
      },
      3: {
        title: "WordPress",
        level: 4,
      },
    }
  },
  socialMedia: {
    title: "Social Media",
    icon: <ResponsiveIcon IconComponent={RocketLaunch}/>,
    tools: {
      1: {
        title: "Instagram",
        level: 5,
      },
      2: {
        title: "LinkedIn",
        level: 3,
      },
      3: {
        title: "Facebook",
        level: 3,
      },
      4: {
        title: "Meta Business Suite",
        level: 1,
      },
    }
  },
  mediaEdit: {
    title: "Bild- und Videobearbeitung",
    icon: <ResponsiveIcon IconComponent={VideoCameraBack}/>,
    tools: {
      1: {
        title: "Affinity Photo",
        level: 3,
      },
      2: {
        title: "DaVinci Resolve",
        level: 4,
      },
      3: {
        title: "Adobe Lightroom",
        level: 3,
      },
      4: {
        title: "Adobe Photoshop",
        level: 2,
      },
      5: {
        title: "Adobe Premiere Pro",
        level: 3,
      },
    },
  },
  design: {
    title: "Gestaltung und Grafikdesign",
    icon: <ResponsiveIcon IconComponent={Draw}/>,
    tools: {
      1: {
        title: "Affinity Designer",
        level: 4,
      },
      2: {
        title: "Affinity Publisher",
        level: 4,
      },
      3: {
        title: "Adobe Illustrator",
        level: 3,
      },
      4: {
        title: "Adobe InDesign",
        level: 2,
      },
      5: {
        title: "Canva",
        level: 4,
      },
    }
  },
}